
@tailwind base;
@layer base {
  html {
    @apply text-gray-800;
  } 
}

@tailwind components;
@tailwind utilities;



body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* color: black; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.p-column-header-content{
  text-align: center !important;
  display: flex;
  justify-content: center;
  padding: 1rem;
}

.p-datatable-emptymessage{
  text-align: center;
  font-size: larger;
}

.p-datatable-wrapper{
  scrollbar-width: none;
}

tr:nth-child(odd){
 
  background-color: #ffffff;
}

tr:nth-child(even){
  background-color: #e8f0fea6;
}

.p-datatable .p-datatable-thead > tr > th {
  background-color: #e8f0fec5 !important;
  /* position: sticky; */
}

td{
  padding: 1rem;
}

.p-highlight{
  background-color: #e8f0fe;
  padding: 0.5rem;
  border-radius: 50%;
  font-size: 1rem;
  height: 1.5rem;
  width: 1rem;
}

.p-paginator-pages{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
}

.p-paginator-bottom{
  padding: 1rem;
}

.p-datatable .p-datatable-tbody > tr > td {
  text-align: center;
}


.scroller{
  scrollbar-width: none;
}
